import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import TransactionScaleImgSource from 'images/blog-images/transaction-scale-china-2016-2020.png';
import EstimatedCostImgSource from 'images/blog-images/estimate-cost-for-cross-border-ecommerce.png';
import FutureDevelopmentImgSource from 'images/featured-image/future-development-of-the-cross-border-ecommerce-logistics-market.png';
import TopLogisticsCompanyImgSource from 'images/blog-images/top-logistics-company.png';



interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query FutureDevelopmentImg {
      file(relativePath: { eq: "future-development-of-the-cross-border-ecommerce-logistics-market.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="future development of the cross border ecommerce logistics market"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Future Development of the Cross-border eCommerce Logistics Market | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-future-development" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "Future Development of the Cross-border eCommerce Logistics Market | Shipkoo",
              content: intl.formatMessage({ id: "blog-post-meta-title-future-development" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: FutureDevelopmentImgSource,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="future-development-of-the-cross-border-ecommerce-logistics-market"
        title="Future Development of the Cross-border eCommerce Logistics Market"
        date="2020-05-27"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <FirstTitle>Logistics market size and growth rate</FirstTitle>

            <p>
              With the acceleration of mobile Internet and globalization, the digital economy is booming. As a new format of foreign trade, the cross-border eCommerce industry has developed rapidly in recent years, with a compound annual growth rate of approximately 30%.
            </p>

            <p>
              According to data from iiMedia Research, the scale of China's cross-border eCommerce transactions reached 9.1 trillion yuan in 2018 and is expected to reach 10.8 trillion yuan in 2019.
            </p>

            <Image src={TransactionScaleImgSource} className="no-top" alt="transaction scale china 2016-2020"/>
            <p className="small">
              Source: Report from iimedia - <a href="https://www.iimedia.cn/c1061/68032.html" target="_blank" rel="noreferrer">https://www.iimedia.cn/c1061/68032.html</a>
            </p>

            <p>
              As a key feature in the cross-border eCommerce industry chain, cross-border eCommerce logistics occupies about 20% -30% of the transaction costs. According to the calculation of China's cross-border eCommerce market scale of 10 trillion yuan, cross-border eCommerce logistics in China's current industry market volume is between 2 trillion and 3 trillion yuan, and the market development space is huge.
            </p>

            <Image src={EstimatedCostImgSource} className="no-top" alt="dropshipping boxes"/>

            <h5>
              The Evolution of China's Cross-border eCommerce Logistics
            </h5>

            <p>
              China's cross-border e-commerce logistics industry has experienced rapid development in the past 10 years. Since the rise of the eBay e-commerce platform in the early stage of the industry's development in 2008, it has experienced an era of postal parcels that have been mailed all over the world.
            </p>

            <p>
              Then in 2010, with the rapid development of AliExpress, the demand for direct delivery of small parcels shipped directly from China to overseas terminal buyers continued to rise, Hong Kong Post and China Post faced short-term shipping capacity shortages. 
            </p>
            
            <p>
              Under the circumstances, a large number of foreign post influx into China through the agency model. During the same period, dozens of foreign postal services such as Singapore Post, Dutch Post, Swedish Post, and Belgian Post landed on the cross-border eCommerce logistics market in China.
            </p>
            
            <p>
              International postal parcel posts within the Universal Postal Union (UPU) system cannot fully meet the high requirements of cross-border eCommerce sellers for logistics timeliness and experience. A large number of cross-border eCommerce logistics companies then launched special line small package products to increase the route and distribution channel.
            </p>

            <p>
              The continuous enrichment of peripheral industries such as cross-border eCommerce logistics and payment methods will better help sellers to gradually develop their products towards the direction of high value and large items. Starting from 2012, more and more sellers have adopted the overseas warehouse delivery model for hot-selling categories, which has greatly improved the shopping experience of overseas buyers of cross-border eCommerce. The development of cross-border eCommerce shows a localization trend.
            </p>

            <p>
              As more and more Chinese cross-border eCommerce goods enter the European and American countries, the tax problem of cross-border eCommerce has aroused widespread concern in the international community. Taxation issues have become an urgent problem to be solved by the European and American governments.
            </p>

            <p>
              Since 2017, developed countries in Europe and America have put forward clear requirements for the standardization of cross-border eCommerce taxation on various platforms. Amazon mandatory required sellers to register for VAT; new regulations on overseas warehouse management promulgated by the UK on April 1, 2019. The legal and tax risks faced by non-compliant cross-border eCommerce logistics channels are increasing, and the compliance operation of cross-border e-commerce logistics tax integration is imminent.
            </p>

            <Image src={TopLogisticsCompanyImgSource} alt="top logistics company" />

            <p className="small">
              Source: SJ Consulting Group - <a href="http://jindel.com/wp-content/uploads/2019/05/Top-50-Global-Logistics-Companies.pdf" target="_blank" rel="noreferrer">http://jindel.com/wp-content/uploads/2019/05/Top-50-Global-Logistics-Companies.pdf</a>
            </p>

            <p>
              In 2018, Amazon's logistics revenue was 42.7 billion US dollars, surpassing DHL and becoming the world's largest logistics company. Amazon's B2C logistics accounts for 12% of the world, with more than 7,000 trucks and more than 40 aircraft. Its current market value is more than 940 billion US dollars, which is more than 40 times that of SF Express and more than 20 times that of JD.com.
            </p> 
            
            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;